<template>
	<ej-blank-page class="d-flex align-items-center">
		<div class="container">
			<div class="row d-flex justify-content-center">
				<div class="col-lg-4 col-md-6 col-11 align-self-center">
					<h2 class="text-center">{{ $route.meta.title }}</h2><br>

					<div class="form-group">
						<input
							type="password"
							placeholder="Senha"
							class="form-control"
							v-model="data.password"
							v-on:keyup.enter="reset"
						>
					</div>

					<div class="form-group">
						<input
							type="password"
							placeholder="Confirmação da Senha"
							class="form-control"
							v-model="data.password_confirmation"
							v-on:keyup.enter="reset"
						>
					</div>

					<div class="form-group">
						<button class="btn btn-primary form-control" @click="reset">
							Redefinir <font-awesome-icon :icon="['fas', 'cog']" spin v-if="reseting"></font-awesome-icon>
						</button>
					</div>
				</div>
			</div>
		</div>

		<ej-footer></ej-footer>
	</ej-blank-page>
</template>

<script>
	import {exibeErro} from "@/helpers";
	import Swal from "sweetalert2";

	export default {
		data() {
			return {
				reseting: false,

				data: {
					password: undefined,
					password_confirmation: undefined,
				}
			}
		},

		methods: {
			reset() {
				const vm = this;

				this.data = Object.assign(this.$route.query, this.data);

				this.reseting = true;
				this.$store.dispatch(`passwords/reset`, this.data)
					.then(function(response) {
						Swal.fire(
							'Sucesso!',
							response.data.message,
							'success'
						).then(() => {
							window.location = '/login';
						});
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.reseting = false;
					})
			}
		}
	}
</script>

<style scoped>

</style>
