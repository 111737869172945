import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=201d130d&scoped=true"
import script from "./reset.vue?vue&type=script&lang=js"
export * from "./reset.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201d130d",
  null
  
)

export default component.exports